









import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      userManager,
      orderFields: [
        { value: "revenue", text: "DSDK MKT" },
        { value: "mktCostPerRevenue", text: "& CPQC / DSDK MKT" },
        { value: "count", text: "Tổng số đơn" },
        { value: "returnPercentage", text: "% Return" },
      ],
    };
  },
});
